
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Order } from "@/core/model/Orders";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ListAttributes } from "@/core/model/ListAttriibutes";
import moment from "moment";
import { displayErrors } from "@/core/helpers/errors";
import UtilsService from "@/core/services/UtilsService";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "catalog-products-list",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Referencia",
        key: "reference",
        sortable: true,
      },
      {
        name: "Nombre",
        key: "name",
        sortable: true,
      },
      {
        name: "Precio",
        key: "price",
        sortable: true,
      },
      {
        name: "QTY",
        key: "quantity",
        sortable: true,
      },
      {
        name: "Marca",
        key: "brand",
        sortable: true,
      },
      {
        name: "Categorías",
        key: "categories",
        sortable: true,
      },
      {
        name: "Creado",
        key: "created_at",
        sortable: true,
      },

      {
        name: "Modificado",
        key: "updated_at",
        sortable: true,
      },
      {
        name: "Estado",
        key: "active",
        sortable: true,
      },
    ]);

    const loading = ref(false);
    const rowsPerPage = ref(25);
    const currentPage = ref(1);
    const total = ref(0);
    const tableData = ref<Array<Order>>([]);
    let sortOrder = "asc";
    let sortField = "name";
    let currentSortColumn = ref("nameasc");
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Productos", ["Catálogo", "Productos"]);
      setCurrentPageActions(false, false, []);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
      } as ListAttributes;
      loading.value = true;
      store
        .dispatch(Actions.CATALOG_PRODUCT_LIST, params)
        .then(() => {
          /*store.state.CatalogModule.products.items.forEach((item) => {
            item.created_at = formatDateTime(item.created_at);
          });*/

          tableData.value.splice(
            0,
            tableData.value.length,
            ...store.state.CatalogModule.products.items
          );
          loading.value = false;
          rowsPerPage.value = store.state.CatalogModule.products.limit;
          currentPage.value = store.state.CatalogModule.products.page;
          total.value = store.state.CatalogModule.products.total;
        })
        .catch(() => {
          displayErrors(store, "CatalogModule");
          loading.value = false;
        });
    };

    const searchItems = () => {
      if (timerSearch) {
        clearTimeout(timerSearch);
        timerSearch = null;
      }
      timerSearch = setTimeout(() => {
        loadData();
      }, 800);
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadData();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadData();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadData();
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      loading,
      rowsPerPage,
      currentPage,
      total,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
      currentSortColumn,
      store,
      formatDateTime,
      UtilsService,
    };
  },
});
