import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Buscar productos"
          }, null, 544), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Datatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.rowsPerPage,
        "current-sort-column": _ctx.currentSortColumn,
        onCurrentChange: _ctx.paginationChangePage,
        onItemsPerPageChange: _ctx.paginationPerPageChange,
        onSort: _ctx.sortList
      }, {
        "cell-reference": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(product.reference), 1)
        ]),
        "cell-name": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(product.name), 1)
        ]),
        "cell-price": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(_ctx.UtilsService.formatAmount(product.price)), 1)
        ]),
        "cell-quantity": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(product.quantity), 1)
        ]),
        "cell-brand": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(product.brand), 1)
        ]),
        "cell-categories": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(product.categories), 1)
        ]),
        "cell-created_at": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDateTime(product.created_at)), 1)
        ]),
        "cell-updated_at": _withCtx(({ row: product }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDateTime(product.updated_at)), 1)
        ]),
        "cell-active": _withCtx(({ row: product }) => [
          _createElementVNode("span", {
            class: _normalizeClass(["badge", product.active ? 'badge-success' : 'badge-warning'])
          }, _toDisplayString(product.active ? 'Activo' : 'Inactivo'), 3)
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "current-sort-column", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ])
  ]))
}